import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Img from 'gatsby-image'


export default props =>
<StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "img/homepage/projects_preview.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data => (
    <Helmet title="Brett Bejcek: Projects">
  <meta name="description" content="Data Science + Design + Community: I live by my top values of zest, curiosity and creativity. At work, I develop predictive models, encode meaning into visusalizations, and run our experimentation platform. Outside of work, I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All thoughts are my own and do not represent clients or employers." />
  <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Brett Bejcek: Projects" />
  <meta property="og:description" content="Data Science + Design + Community: I live by my top values of zest, curiosity and creativity. At work, I develop predictive models, encode meaning into visusalizations, and run our experimentation platform. Outside of work, I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All thoughts are my own and do not represent clients or employers." />
  <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
  <meta name="twitter:title" content="Brett Bejcek: Projects" />
  <meta name="twitter:description" content="Data Science + Design + Community: I live by my top values of zest, curiosity and creativity. At work, I develop predictive models, encode meaning into visusalizations, and run our experimentation platform. Outside of work, I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All thoughts are my own and do not represent clients or employers." />
  <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
    </Helmet>
  )}
/>
