import React from "react"
import Layout2 from "../components/layout"
import { StaticQuery, graphql } from "gatsby"
import Preview from './../elements/projectpreview.js'
import Button from './../elements/button.js'
import { Helmet } from "react-helmet"
import ProjectsSEO from './../elements/projectsseo.js'
import Footer from "../components/footer"
export default () =>
<Layout2>
<ProjectsSEO/>
<div css = {{'@media (max-width: 900px)':{marginLeft:`5%`, marginRight:`5%`}}}>
<div css = {{marginTop:`50px`, minHeight:`10rem`, borderRadius:`20px`, background:`#7d4aa3`, marginBottom:`2rem`, padding:`2rem`, color:`white`, '@media (max-width: 400px)':{marginLeft:`10%`, marginRight:`10%`}}}><h1 >Projects</h1><p css={{color:`white`}}>Just trying out new ideas and seeing what happens. <a href="mailto:brett@brettbejcek.com"><u> Let me know if anything looks interesting.</u></a></p></div>
<div css={{display:`grid`, gridTemplateColumns: `repeat(2, 1fr)`, gridGap: `2rem`,'@media (max-width: 400px)': {gridTemplateColumns: `repeat(1, 1fr)`, gridGap: `1.5rem`, margin:`10%`}}}>
<StaticQuery
  query={graphql`
    query allProjects {
      allMarkdownRemark(sort: { fields: [frontmatter___completion_date], order: DESC }, filter: {frontmatter: {content: {in: ["project", "ghostprojectlink", "ghostprojectlinkexternal"]}}}){
        edges {
          node {
            excerpt(pruneLength:500)
            frontmatter {
              title
              path
              completion_date(formatString: "YYYY")
              location
              content
              img_background {
                childImageSharp {
                  fluid(maxWidth:500) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                } }
              img_text {   childImageSharp {
                  fluid(maxWidth:500) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                } }
            }
          }
        }
      }
    }
  `}
  render={data => (
    data.allMarkdownRemark.edges.map(({ node }) => (
      <Preview content= {node.frontmatter.content} background_img = {node.frontmatter.img_background.childImageSharp.fluid} text_img = {node.frontmatter.img_text.childImageSharp.fluid} key = {node.frontmatter.title} path= {node.frontmatter.path}></Preview>
          ))
  )}
/>
</div>
<br/>
<Button link = "/" cta ='back to home' css={{verticalAlign:`middle`}}></Button>
<br/>
<br/>
</div>
<Footer/>
</Layout2>
